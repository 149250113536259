// SECTION :: START

.schedule {
  padding-top: 170px;
  padding-bottom: 130px;
  @include adaptive(1439) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  @include adaptive(767) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &__btn {
    padding-bottom: 130px;
    @include adaptive(1439) {
      padding-bottom: 75px;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;

    &-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 70px;
      @include adaptive(1279) {
        gap: 32px;
      }
      @include adaptive(1023) {
        gap: 16px;
      }
      @include adaptive(767) {
        grid-template-columns: repeat(1, 1fr);
        gap: 64px;
      }
    }

    &-head {
      overflow: hidden;
      position: relative;
      @include imageAnimation();

      &:hover {
        > div {
          img {
            transform: scale(1.1) rotate(3deg);
            @include transition(transform);
          }
        }

        svg {
          transform: translateX(-50%);
          @include transition(transform);
        }
      }

      img {
        @extend %imageResCoverAbs;
        position: relative;
        display: block;
        max-width: 100%;
        height: auto;
        @include transition(transform);
      }

      button {
        cursor: pointer;
        outline: none;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background-color: #6ea5a9;
        @include adaptive(767) {
          width: 75px;
          height: 75px;
        }

        svg {
          fill: #fff;
          @include transition(transform);
        }
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 50px;
      height: 100%;
      max-height: 450px;
    }

    &-link {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 22px;
      color: #6ea5a9;
      margin-top: auto;
      @include linkHoverState(#000, #000);
      @include adaptive(1279) {
        font-size: 20px;
      }
      @include adaptive(767) {
        font-size: 18px;
      }

      &:hover {
        i {
          transform: rotate(180deg);
          @include transition(transform);
        }
      }

      i {
        font-style: normal;
        margin-right: 5px;
        @include transition(transform);
      }
    }
  }
}

// SECTION :: END
