// SECTION :: START

.staff {
  padding-top: 100px;
  padding-bottom: 250px;
  @include adaptive(1439) {
    padding-bottom: 200px;
  }
  @include adaptive(1279) {
    padding-bottom: 150px;
  }
  @include adaptive(767) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &__wrapper {
    &-1 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 80px;
      @include adaptive(767) {
        grid-template-columns: 1fr;
        padding-bottom: 50px;
      }
    }

    &-2 {
      img {
        position: relative;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__content {
    width: 100%;
    max-width: 580px;
    margin-left: auto;
  }

  &__media {
    @include imageAnimation();
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 40px;

    a {
      display: flex;
      font-weight: bold;
      font-size: 22px;
      color: #6ea5a9;
      @include linkHoverState(#000, #000);
      @include adaptive(1439) {
        font-size: 20px;
      }
      @include adaptive(1023) {
        font-size: 18px;
      }
      @include adaptive(767) {
        font-size: 16px;
      }

      &:hover {
        i {
          transform: rotate(180deg);
          @include transition(transform);
        }
      }

      i {
        font-style: normal;
        margin-right: 5px;
        transform-origin: center;
        @include transition(transform);
      }
    }
  }
}

// SECTION :: END
