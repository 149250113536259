// BUTTON :: START

.c-btn {
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 115px;
  height: 42px;
  margin: 0;
  padding-left: 25px;
  padding-right: 25px;
  border: 1px solid transparent;
  border-radius: 0;
  box-shadow: none;
  background-color: #6ea5a9;
  font-size: 16px;
  color: #fff;
  @include transition(background-color, color);
  @include adaptive(767) {
    min-width: 100px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &:hover {
    background-color: transparent;
    color: #fff;
    @include transition(background-color, color);
    animation-name: btnAnimation;
    animation-timing-function: ease-in-out;
    animation-duration: 0.5s;
    animation-fill-mode: both;

    &:after {
      animation-name: btnAfterAnimation;
      animation-timing-function: ease-in-out;
      animation-duration: 0.5s;
      animation-fill-mode: both;
    }
  }

  &:after {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #000;
  }

  &.c-btn--black {
    background-color: #000000;
  }

  &__wrapper {
    display: flex;
    align-items: center;

    &--center {
      justify-content: center;
    }
    &--between {
      justify-content: space-between;
    }
  }

  & + .c-btn {
    margin-left: 15px;
  }
}

@keyframes btnAnimation {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: #000;
    color: #ffffff;
  }
}
@keyframes btnAfterAnimation {
  0% {
    width: 0;
    left: 0;
  }
  49% {
    width: 49%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

// BUTTON :: END
