// SECTION :: START

.services {
  padding-top: 100px;
  padding-bottom: 100px;
  @include adaptive(1279) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &__wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    @include adaptive(1023) {
      grid-template-columns: 1fr;
      gap: 32px;
    }
  }

  &__content {
    width: 100%;
    max-width: 580px;
    @include adaptive(1023) {
      max-width: 100%;
    }
  }

  &__media {
    position: relative;
    @include imageAnimation();

    &:hover {
      .services__link {
        svg {
          transform: translateX(-5px);
          @include transition(transform);
        }
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  &__nav {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px 75px;
    background-color: #fff;
    transform: translate(-60px, 75px);
    @include adaptive(1279) {
      padding: 32px;
    }
    @include adaptive(1023) {
      transform: translate(0, 75px);
    }
    @include adaptive(767) {
      padding: 32px 16px;
    }

    a {
      font-weight: bold;
      font-size: 22px;
      color: #343434;
      @include linkHoverState(#6ea5a9, #6ea5a9);
      @include adaptive(1439) {
        font-size: 20px;
      }
      @include adaptive(1023) {
        font-size: 18px;
      }
      @include adaptive(767) {
        font-size: 16px;
      }

      &:not(:last-of-type) {
        margin-bottom: 40px;
        @include adaptive(1279) {
          margin-bottom: 16px;
        }
        @include adaptive(767) {
          margin-bottom: 16px;
        }
      }
      &:last-of-type {
        color: #6ea5a9;
      }
      i {
        font-style: normal;
        margin-right: 5px;
        @include transition(transform);
      }
      &:hover i {
          transform: rotate(180deg);
          transition: transform 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }

  &__link {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: #6ea5a9;
    @include adaptive(767) {
      width: 75px;
      height: 75px;
    }

    svg {
      fill: #fff;
      @include transition(transform);
    }
  }
}

// SECTION :: END
