// SECTION :: START

.reviews {
  position: relative;
  padding-top: 215px;
  padding-bottom: 225px;
  @include adaptive(1439) {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  @include adaptive(767) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .c-grid {
    max-width: 1420px;
  }

  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#262626, 0.8);
    }

    &-cover {
      @extend %imageResCoverAbs;

    }

    &-visual {
      z-index: 1;
      position: absolute;
      top: 165px;
      left: 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      max-width: 320px;
      height: 180px;
      background-color: #6ea5a9;
      @include adaptive(1439) {
        top: 90px;
      }
      @include adaptive(767) {
        max-width: 75%;
      }

      span {
        transform: translateX(-15%) translateY(15%);
        font-family: "Tenor Sans", sans-serif;
        font-weight: normal;
        font-size: 293px;
        line-height: 1;
        color: #fff;
        @include adaptive(1279) {
          transform: translateX(-25%) translateY(5%);
          font-size: 250px;
        }
        @include adaptive(767) {
          display: none;
        }
      }
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
    padding-left: 150px;
    @include adaptive(767) {
      padding-left: 0;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;

    .c-title {
      margin-bottom: 20px;
      border-bottom: 1px solid #fff;
    }

    &-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 70px;
      column-gap: 100px;
      @include adaptive(767) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 32px;
        column-gap: 0;
      }
    }
  }
}

// SECTION :: END
