// FOOTER :: START

.footer {
  position: relative;
  padding-bottom: 95px;
  @include adaptive(767) {
    padding-bottom: 60px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: calc(100% - 100px);
    background-color: #edf1f4;
  }

  &__wrapper {
    z-index: 2;
    position: relative;
  }

  &__logo {
    padding-bottom: 60px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
    @include adaptive(767) {
      flex-direction: column;
    }
  }

  &__rating {
    display: flex;
    align-items: center;
    padding-right: 15px;
    @include adaptive(767) {
      padding-right: 0;
      padding-bottom: 30px;
    }

    > div {
      display: flex;
      align-items: center;
      padding-right: 10px;

      .icon-svg {
        &:not(:last-of-type) {
          margin-right: 5px;
        }
      }
    }

    p {
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.02em;
      color: #000;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    padding-left: 15px;
    @include adaptive(767) {
      padding-left: 0;
    }

    a {
      display: flex;
      color: #6ea5a9;

      &:not(:last-of-type) {
        margin-right: 17px;
      }
    }
  }
}

// FOOTER :: END
