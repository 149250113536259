// SECTION :: START

.presentations {
  position: relative;
  padding-top: 150px;
  padding-bottom: 150px;
  @include adaptive(1023) {
    padding-top: 100px;
    padding-bottom: 75px;
  }
  @include adaptive(767) {
    padding-top: 75px;
  }

  &__bg {
    z-index: 0;
    position: absolute;
    top: 145px;
    left: 0;
    display: flex;
    width: 100%;
    height: calc(100% - 290px);
    @include imageAnimation();
    @include adaptive(767) {
      position: static;
      height: auto;
      flex-wrap: wrap;
      padding-left: 16px;
      padding-right: 16px;
    }

    > div {
      display: flex;
      align-items: flex-start;
      width: 50%;
      @include adaptive(767) {
        width: 100%;
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-left: auto;
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    @include adaptive(767) {
      flex-wrap: wrap;
    }

    > div {
      width: 50%;
      padding-right: 16px;
      @include adaptive(767) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 32px;
      }
    }
  }

  .awards {
    padding: 0;
  }
}

// SECTION :: END
