// SECTION :: START

.meet {
  position: relative;
  padding-top: 100px;
  padding-bottom: 200px;
  @include adaptive(1279) {
    padding-bottom: 100px;
  }
  @include adaptive(767) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &.meet--home {
    margin-bottom: 75px;
  }

  &.meet--about,
  &.meet--me {
    padding-bottom: 100px;
    @include adaptive(767) {
      padding-bottom: 75px;
    }

    .meet {
      &__wrapper {
        grid-template-columns: 585px 1fr;
        @include adaptive(1279) {
          grid-template-columns: 1fr 1fr;
        }
        @include adaptive(1023) {
          grid-template-columns: 1fr;
        }

        > div {
          &:nth-of-type(1) {
            order: 2;
          }
          &:nth-of-type(2) {
            order: 1;
          }
        }
      }

      &__achieve {
        left: auto;
        right: 0;
      }
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 585px;
    gap: 60px;
    @include adaptive(1279) {
      grid-template-columns: 1fr 1fr;
      gap: 32px;
    }
    @include adaptive(1023) {
      grid-template-columns: 1fr;
    }

    > div {
      &:nth-of-type(1) {
        display: flex;
        align-items: flex-start;

        @include adaptive(767) {
          display: block;
        }

        img {
          @extend %imageResCoverAbs;
          position: relative;
        }
      }
    }

    &-media {
      @include imageAnimation();
    }
  }

  &__achieve {
    z-index: 5;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 952px;
    max-width: 50%;
    min-height: 212px;
    padding: 40px 90px;
    background-color: #6ea5a9;
    gap: 2rem;
    @include adaptive(1023) {
      max-width: 75%;
      min-height: unset;
      padding: 64px 32px;

      > div {
        &:nth-of-type(2) {
          padding-left: 32px;
        }
      }
    }
    @include adaptive(767) {
      position: static;
      max-width: 100%;
      padding: 32px 16px;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      transform: translateX(-50%);
      @include adaptive(1023) {
        width: 100%;
        min-width: 75px;
        max-width: 75px;
        transform: unset;
      }
    }

    .c-title {
      padding-bottom: 0;
      line-height: 1.7;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 40px;

    @include adaptive(767) {
      padding-bottom: 40px;
    }

    a {
      display: flex;
      font-weight: bold;
      font-size: 22px;
      color: #6ea5a9;
      @include linkHoverState(#000, #000);
      @include adaptive(1439) {
        font-size: 20px;
      }
      @include adaptive(1023) {
        font-size: 18px;
      }
      @include adaptive(767) {
        font-size: 16px;
      }

      &:hover {
        i {
          transform: rotate(180deg);
          @include transition(transform);
        }
      }

      i {
        font-style: normal;
        margin-right: 5px;
        @include transition(transform);
      }
    }
  }
}

// SECTION :: END
