// SECTION :: START

.procedures {
  padding-top: 100px;
  padding-bottom: 100px;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    @include adaptive(767) {
      grid-template-columns: 1fr;
    }

    &-1 {
      padding-bottom: 95px;
      @include adaptive(767) {
        padding-bottom: 75px;
      }
    }

    &-2 {
      position: relative;
      @include adaptive(1023) {
        grid-template-columns: 1fr;
      }
    }
  }

  &__desc {
    width: 100%;
    max-width: 580px;
    margin-left: auto;

    &:first-of-type {
      padding-top: 32px;
      @include adaptive(767) {
        padding-top: 0;
      }
    }
  }

  &__logo {
    z-index: 3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include adaptive(767) {
      width: 100%;
      max-width: 100px;
    }
  }

  &__subtitle {
    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;

    // &:after {
    //   border-bottom: 1px solid #FFF;
    //   padding-top: 4px;
     
    // }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -20px;
      width: 0;
      height: 1px;
      background-color: white;
      transition: width 0.3s ease;
    }
    
  }

  &__media {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {

      h3::after{
        width: 45%;
      }

      .procedures__media-nav nav {
        transition: all .3s ease;
        margin-top: 60%;
    }


      
    }

    &-nav {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      opacity: 0;
      background: rgb(0 0 0 / 30%);

      nav {
        margin-top:55%;
        // margin-left: 40%;
      }

      ul {
        text-align: center;
        li a {
          color: #FFF;
          font-size: 22px;
          font-weight: 700;
          transition: color .3s ease;
  
          &:hover {
            color: #6ea5a9;
          }
      }
      }
    }

    &:before {
      transition: all .3s ease-in;
      // background-color: rgba(0,0,0,.2);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }

    &:hover {
      .procedures__subtitle {
        transition: all .3s ease-in-out;
        // opacity: 0;
      }
      .procedures__media-nav {
        transition: all .3s ease-in-out;
        // transform: translateY(30px);
        opacity: 1;
        z-index:999;
      }
    }
    &:hover:before {
      // background-color: rgba(0,0,0,.5);
      // background-color: #6ea5a9;
    }

    &-1 {
      transform: translateY(-90px);
      @include adaptive(1023) {
        transform: translateY(0);
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      @include adaptive(767) {
        min-height: 375px;
      }
    }
  }

  &__visual {
    @include imageAnimation();
  }

  @media (hover: none) {
  
      .procedures__logo {
  
        svg {
          width: 100px;
          height: 100px;
        }
  
      }
  
      .procedures__media-nav {
        opacity: 1;
      }
  
      .procedures__subtitle {
        top: 15%;

        @media (min-width:769px) and (max-width:1024px) {
          top: 25%;
        }
      }
  
      .procedures__media-1 {
  
        .procedures__media-nav nav {
          margin-top: 25%;

          @media (min-width:769px) and (max-width:1024px) {
            margin-top: 35%;
          }
        }
  
      }
  
      .procedures__media-2 {
  
        .procedures__subtitle {
          top: 45%;
        }
  
      }
  
  
    }

}


// SECTION :: END
