// SECTION :: START

.present {
  position: relative;
  padding-top: 170px;
  padding-bottom: 135px;
  @include adaptive(1439) {
    padding-top: 125px;
    padding-bottom: 125px;
  }
  @include adaptive(767) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .c-grid {
    max-width: 1530px;
  }

  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left center;
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;

    &-1 {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      gap: 60px;
      padding-bottom: 60px;
      @include adaptive(767) {
        grid-template-columns: 1fr;
        gap: 0;
        padding-bottom: 32px;
      }
    }

    &-2 {
      @include adaptive(767) {
        gap: 16px;
      }

      img {
        @extend %imageResCoverAbs;
        position: relative;
      }
    }
  }

  &__media {
    @include imageAnimation();
  }
}

// SECTION :: END
