// SECTION :: START

.contact {
  padding-top: 120px;
  padding-bottom: 120px;
  @include adaptive(1279) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @include adaptive(767) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &__title {
    padding-bottom: 20px;
  }

  &__desc {
    width: 100%;
    max-width: 536px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
    text-align: center;

    a {
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      color: #6ea5a9;
      @include linkHoverState(#6ea5a9, #6ea5a9);

      strong {
        font-weight: bold;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;

    &-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include adaptive(767) {
        flex-wrap: wrap;
      }

      > div {
        width: 100%;
        max-width: calc((100% - 16px) / 2);
        @include adaptive(767) {
          max-width: 100%;
        }
      }
    }

    &-field {
      position: relative;
      padding-bottom: 25px;

      &--btn {
        padding-top: 25px;
        padding-bottom: 0;
      }

      label {
        display: flex;
        width: 100%;
      }

      input,
      select,
      textarea {
        outline: none;
        position: relative;
        display: flex;
        font-family: Arial, sans-serif;
        width: 100%;
        height: 75px;
        padding: 0 30px;
        border: 1px solid #e0e0e0;
        box-shadow: none;
        border-radius: 0;
        background-color: #fff;
        color: #767676;
        font-size: 21px;
        @include inputPlaceholder {
          font-size: 21px;
          color: #767676;
        }
        @include adaptive(767) {
          height: 65px;
          padding: 0 20px;
          font-size: 18px;
          @include inputPlaceholder {
            font-size: 18px;
          }
        }
      }

      textarea {
        resize: none;
        height: 250px;
        padding: 30px;
        @include adaptive(767) {
          height: 200px;
          padding: 20px;
        }
      }
    }
  }
}

#search__form {

  input,
      select,
      textarea {
        outline: none;
        position: relative;
        display: flex;
        width: 100%;
        height: 75px;
        padding: 0 30px;
        border: 1px solid #e0e0e0;
        box-shadow: none;
        border-radius: 0;
        background-color: #fff;
        font-size: 21px;
        @include inputPlaceholder {
          font-size: 21px;
          color: #767676;
        }
        @include adaptive(767) {
          height: 65px;
          padding: 0 20px;
          font-size: 18px;
          @include inputPlaceholder {
            font-size: 18px;
          }
        }
      }
      
}

.wpcf7-not-valid-tip,
.screen-reader-response {
  color: red;
}

// SECTION :: END
