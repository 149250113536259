// SECTION :: START

.education {
  padding-top: 100px;
  padding-bottom: 100px;
  @include adaptive(1279) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    min-height: 274px;
    padding: 30px 75px 45px;
    background-color: #fff;
    @include adaptive(767) {
      &:not(:last-of-type) {
        border-bottom: 1px solid #c4c4c4 !important;
      }
    }

    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3) {
      border-bottom: 1px solid #c4c4c4;
    }
    &:nth-of-type(4) {
      @include adaptive(1023) {
        border-bottom: 1px solid #c4c4c4;
      }
    }

    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(4),
    &:nth-of-type(5) {
      border-right: 1px solid #c4c4c4;
      @include adaptive(767) {
        border: none;
      }
    }
    &:nth-of-type(3) {
      @include adaptive(1023) {
        border-right: 1px solid #c4c4c4;
      }
      @include adaptive(767) {
        border: none;
      }
    }

    p {
      padding-bottom: 30px;
      font-weight: bold;
      font-size: 22px;
      text-align: center;
      color: #343434;
    }

    ul {
      display: grid;
      align-items: center;

      li {
        list-style-type: none;
      }

      &.two {
        grid-template-columns: 1fr 1fr;
        column-gap: 55px;
        row-gap: 15px;
        @include adaptive(767) {
          grid-template-columns: 1fr;
          column-gap: 0;
          row-gap: 16px;
        }
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      border: 1px solid #c4c4c4;
      @include adaptive(1023) {
        grid-template-columns: 1fr 1fr;
      }
      @include adaptive(767) {
        grid-template-columns: 1fr;
      }
    }
  }
}

// SECTION :: END
