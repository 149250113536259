html,
body {
  width: 100%;
  height: 100%;

  &.is-hideScroll {
    overflow: hidden !important;
    width: 100%;
  }
}

html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  line-height: 1.42857143;
  font-size: 14px;
  font-weight: normal;
  font-family: proxima-nova, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  > .main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 auto;

    &--start {
      justify-content: flex-start;
    }
  }
}

* {
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button[disabled] {
  opacity: 0.55;
  cursor: not-allowed;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
}

p,
h1, h2, h3, h4, h5, h6,
hr {
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-family: "Tenor Sans", sans-serif;
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: 20px;
}
h1 {
  font-size: 3.2rem;
}
h2 {
  font-size: 2.8rem;
}
h3 {
  font-size: 2.5rem;
}
h4 {
  font-size: 2.2rem;
}
h5 {
  font-size: 1.8rem;
}
h6 {
  font-size: 1.6rem;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

a {
  outline: none;
  text-decoration: none;
  color: #6ea5a9;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #000;
  }
}

input[type="text"] {
  appearance: none;
}

picture {
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

main {

  img {

    @include adaptive(767) {
      object-fit: cover;
      width: 100%;
      height: auto;
    }
    
  }
}
