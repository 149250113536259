@import "function";

////
/// @group  Variable
////

/// @name object
$object: (
  colors : (
    white                 : #fff,
    black                 : #000,

    primary               : #4285f4,

    success               : #00c851,
    info                  : #33b5e5,
    warning               : #ff8800,
    danger                : #cc0000
  ),
);

/// MAIN PALLET
/// ===============
$c-white                  : return-value(colors, white);
$c-black                  : return-value(colors, black);

$c-primary                : return-value(colors, primary);

$c-success                : return-value(colors, success);
$c-info                   : return-value(colors, info);
$c-warning                : return-value(colors, warning);
$c-danger                 : return-value(colors, danger);

/// ADDITIONAL PALLET
/// ===============
