// SECTION :: START

.facelift {
  padding-top: 100px;
  padding-bottom: 100px;
  @include adaptive(1023) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &.facelift--step {
    padding-top: 200px;
    padding-bottom: 0;
    @include adaptive(1439) {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .facelift {
      &__wrapper {
        grid-template-columns: 1fr 640px;
        @include adaptive(1279) {
          grid-template-columns: 1fr 500px;
        }
        @include adaptive(1023) {
          grid-template-columns: 1fr;

          > div {
            &:nth-of-type(1) {
              order: 2;
            }
            &:nth-of-type(2) {
              order: 1;
            }
          }
        }
      }
    }
  }

  &__wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: 640px 1fr;
    gap: 60px;
    @include adaptive(1279) {
      grid-template-columns: 500px 1fr;
      gap: 32px;
    }
    @include adaptive(1023) {
      grid-template-columns: 1fr;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }

    ul {
      display: flex;
      flex-direction: column;
      padding-top: 40px;

      li {
        display: flex;
        align-items: center;
        width: 100%;
        height: 85px;
        padding-left: 35px;
        padding-right: 35px;
        border: 1px solid #c4c4c4;
        background-color: #fff;
        font-weight: bold;
        font-size: 16px;
        color: #343434;
        @include adaptive(767) {
          font-size: 14px;
        }

        i {
          min-width: 6px;
          height: 6px;
          margin-right: 15px;
          background-color: #6ea5a9;
        }

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__media {
    @include imageAnimation();
  }
}

// SECTION :: END
