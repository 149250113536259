// SECTION :: START

.our {
  padding-top: 100px;
  padding-bottom: 100px;
  @include adaptive(1023) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &.our--cost {
    padding-bottom: 300px;
    @include adaptive(1365) {
      padding-bottom: 200px;
    }
    @include adaptive(1023) {
      padding-bottom: 150px;
    }

    .our {
      &__wrapper {
        align-items: flex-start;
        @include adaptive(1365) {
          align-items: stretch;
        }

        &-left {
          transform: translateY(-15%);
          @include adaptive(1365) {
            transform: unset;
          }         

        }
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    @include adaptive(1365) {
      align-items: stretch;
    }
    @include adaptive(767) {
      flex-wrap: wrap;
    }

    &-left {
      z-index: 3;
      position: relative;
      width: 100%;
      max-width: 536px;
      min-height: 649px;
      transform: translateY(30%);
      padding: 95px 75px;
      background-color: #484848;
      @include adaptive(1365) {
        transform: unset;
        min-height: unset;
        height: auto;
      }
      @include adaptive(1279) {
        max-width: 40%;
        padding: 64px 32px;
      }
      @include adaptive(767) {
        order: 2;
        max-width: 100%;
        padding: 32px 16px;
      }

      li {
        list-style: square;
        line-height: 2;
        margin-left: 2em;
        color: #fff;
      }

    }

    &-right {
      z-index: 1;
      position: relative;
      width: 100%;
      max-width: 950px;
      min-height: 1280px;
      margin-left: auto;
      padding: 85px 75px;
      background-color: #edf1f4;
      @include adaptive(1279) {
        max-width: 60%;
        padding: 64px 32px;
      }
      @include adaptive(767) {
        order: 1;
        max-width: 100%;
        padding: 32px 16px;
      }

      .c-desc {
        width: 100%;
        max-width: 398px;
        margin-left: auto;
        @include adaptive(1535) {
          max-width: calc(100% - (316px + 32px));
        }
        @include adaptive(1365) {
          max-width: 100%;
        }
      }
    }
  }

  &__media {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translate(-275px, -50%);
    left: 0;
    width: 674px;
    @include imageAnimation();
    @include adaptive(1365) {
      position: static;
      transform: unset;
      width: auto;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__extra {
    text-align: center;
  }
}

// SECTION :: END
