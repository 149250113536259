// SECTION :: START

.practice {
  position: relative;
  padding-top: 65px;
  padding-bottom: 460px;
  @include adaptive(1799) {
    padding-bottom: 150px;
  }
  @include adaptive(1279) {
    padding-top: 75px;
    padding-bottom: 100px;
  }
  @include adaptive(1023) {
    padding-bottom: 75px;
  }

  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      @extend %imageResCoverAbs;
      object-position: center bottom;
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 535px;
    gap: 290px;
    @include adaptive(1799) {
      grid-template-columns: 1fr;
      gap: 75px;
    }
    @include adaptive(1023) {
      gap: 0;
    }

    &-left {
      position: relative;
      padding: 85px;
      background-color: #edf1f4;
      @include adaptive(1799) {
        padding-right: 50%;
      }
      @include adaptive(1279) {
        padding: 64px 50% 64px 32px;
      }
      @include adaptive(1023) {
        padding-right: calc(50% + 16px);
      }
      @include adaptive(767) {
        padding: 32px 16px;
      }

      > div {
        width: 100%;
        max-width: calc(100% - 300px);
        @include adaptive(1799) {
          max-width: 100%;
        }
      }
    }

    &-right {
      position: relative;
      margin-top: 100px;
      padding: 95px 75px;
      background-color: #484848;
      @include adaptive(1799) {
        max-width: 50%;
      }
      @include adaptive(1279) {
        padding: 64px 32px;
      }
      @include adaptive(1023) {
        max-width: 100%;
        margin-top: 0;
      }
      @include adaptive(767) {
        padding-bottom: 132px;
      }

      .c-desc {
        strong {
          color: #6ea5a9;
        }
      }
    }
  }

  &__me {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    max-width: 100%;
    height: auto;
    transform: translate(55%, 45%);
    @include imageAnimation();
    @include adaptive(1799) {
      transform: translate(0, 55%);
    }
    @include adaptive(1439) {
      max-width: 50%;
    }
    @include adaptive(1023) {
      top: 50%;
      transform: translateY(-50%);
    }
    @include adaptive(767) {
      position: static;
      max-width: 100%;
      margin-top: 50px;
      transform: unset;
    }

    img {
      
    }
  }

  &__signature {
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: 40px;
  }

  &__logo {
    opacity: 0.3;
    display: flex;
    align-items: center;

    img {
      display: block;
      max-width: 100%;
      height: auto;

      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }
  }

  &__link {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: #6ea5a9;
    @include adaptive(767) {
      width: 100%;
    }

    &:hover {
      svg {
        transform: translateX(-5px);
        @include transition(transform);
      }
    }

    svg {
      fill: #fff;
      @include transition(transform);
    }
  }
}

// SECTION :: END
