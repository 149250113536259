// SECTION :: START

.portfolio {
  padding-top: 140px;
  padding-bottom: 240px;
  @include adaptive(1439) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  @include adaptive(767) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &.portfolio--fps {
    padding-bottom: 100px;

    .portfolio {
      &__wrapper {
        &-2 {
          align-items: center;
          @include adaptive(1023) {
            grid-template-columns: 1fr;
          }
        }
      }

      &__media {
        margin-bottom: 0;
      }

      &__logo {
        @include adaptive(1023) {
          display: none;
        }
      }
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    @include adaptive(767) {
      grid-template-columns: 1fr;
    }

    &-1 {
      align-items: center;
      padding-bottom: 70px;
      @include adaptive(767) {
        padding-bottom: 50px;
      }

      .c-title {
        padding-bottom: 0;
      }
    }

    &-2 {
      @include adaptive(767) {
        gap: 50px;
      }
    }
  }

  &__desc {
    width: 100%;
    max-width: 582px;
    margin-left: auto;
    @include adaptive(767) {
      max-width: 100%;
    }
  }

  &__media {
    position: relative;
    margin-bottom: 60px;
    @include imageAnimation();
    @include adaptive(767) {
      margin-bottom: 32px;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  &__logo {
    z-index: 2;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    @include adaptive(1023) {
      width: 100%;
      max-width: 100px !important;
    }
    @include adaptive(767) {
      display: none !important;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.portfolio__nav--fps {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      padding-left: 150px;
      @include adaptive(1439) {
        padding-left: 75px;
      }
      @include adaptive(1023) {
        padding-left: 0;
      }
      @include adaptive(767) {
        flex-direction: column;
        align-items: center;
      }

      li {
        display: flex;
        justify-content: flex-start;
        width: 50%;
        @include adaptive(767) {
          width: 100%;
          justify-content: center;
        }

        &:nth-of-type(odd) {
          padding-right: 15px;
          @include adaptive(767) {
            padding-right: 0;
          }
        }

        &:first-of-type {
          padding-bottom: 40px;

          // .page-id-49 & {
          //   padding-bottom: 0;
          // }
          
          @include adaptive(1439) {
            padding-bottom: 32px;
          }
          @include adaptive(767) {
            padding-bottom: 16px;
          }
        }

        a {
          display: flex;
          text-align: left;
        }
      }
    }

    li {
      &:not(:last-of-type) {
        padding-bottom: 40px;
        @include adaptive(1439) {
          padding-bottom: 32px;
        }
        @include adaptive(767) {
          padding-bottom: 16px;
        }
      }

      &:first-of-type {
        padding-bottom: 0;
      }
    }

    a {
      display: block;
      font-weight: bold;
      font-size: 22px;
      text-align: center;
      color: #343434;
      @include linkHoverState(#6ea5a9, #6ea5a9);
      @include adaptive(1279) {
        font-size: 20px;
      }
      @include adaptive(767) {
        font-size: 16px;
      }
    }
  }

  .case-box__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 275px;
    justify-content: space-between;
    padding: 25px;    
  
    div:last-child {
      display: flex;
      justify-content: center;
  
      span {
        margin: 1rem auto;
      }
    }
  }

  .patient-list {
    display: grid;
    grid-template-columns: repeat(3,1fr);
  
    @include adaptive(767) {
      grid-template-columns: 1fr;
    }
  }

  .gallery-links,
  .patient-navigation {
    padding: 1.75rem 0;
  }

  .patient-navigation {
    .c-btn {
      max-width: 160px;
    }
  }

  .gallery__links {
    margin-bottom: 2.5rem;
  }

  .procedure-link {
    display: flex;
    justify-content: flex-end;
  }

  .parent-link,
  .procedure-link {
    @include adaptive(767) {
      min-width: 150px;
    }
  }

  .nav-label {
    text-align: center;
  }
  
  .parent-link {
    justify-self: center;
  }
  
  .procedure-link {
    justify-self: center;
  }
  
  .patient-stage {
    text-align: center;
    margin: 1rem auto;
  }
  
  &.gallery-single {
    width: 65vw;
    margin: 0 auto;
  
    @include adaptive(767) {
      width: 100%;
    }
  }

  .case-box__images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  
    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  
  }

  .patient-listing__wrapper .nav-next {
    display: flex;
    justify-content: flex-end; 
  }

}

.heading__title {
  
  span {
    font-size: 1rem;
    line-height: 2;
  }
  
}

.popup-overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $c-white;
    padding: 100px;
    text-align: center;
    position: relative;

    @include adaptive(767) {
      padding: 50px;
      margin: 15px;
    }

    .popup__logo {

      img {
        margin: 1rem auto 0;
        height: 75px;
      }

    }
  }
  
  .close-box {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
  }

}



// SECTION :: END
