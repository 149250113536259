// SECTION :: START

.gallery {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  @include adaptive(1439) {
    padding-top: 50px;
  }
  @include adaptive(767) {
    padding-bottom: 50px;
  }

  &.gallery--pediatric-procedure {
    .gallery {
      &__bg {
        &:after {
          background-color: rgba(#000, 0.55);
        }
      }
      &__desc {
        padding-left: 40px;
        border-left: 1px solid #fff;
        color: #ffffff;

        &:last-of-type {
          padding-bottom: 0;
          margin-bottom: 60px;
        }
      }

      &__nav {
        a {
          color: #ffffff;
          @include linkHoverState(#fff, #fff);
        }
      }
    }
  }

  &.gallery--facelift {
    margin-bottom: 100px;

    .gallery {
      &__bg {
        img {
          object-position: left center;
        }
      }

      &__wrapper {
        > div {
          margin-left: 0;
        }
      }
    }
  }

  &__bg {
    z-index: 0;
    position: absolute;
    top: 125px;
    left: 0;
    width: 100%;
    height: calc(100% - (100px + 250px));
    @include adaptive(1439) {
      top: 75px;
      height: calc(100% - (50px + 250px));
    }
    @include adaptive(767) {
      height: calc(100% - (50px + 150px));
    }

    &:after {
      content: '';
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;     
      @include adaptive(767) {
        background-color: #000;
        opacity: .3;
      }
    }

    .c-grid {
      position: relative;
      height: 100%;
      @include imageAnimation();
    }

    img {
      @extend %imageResCoverAbs;
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1420px;
    margin-left: auto;
    margin-right: auto;
    padding: 140px 15px 200px;
    @include adaptive(767) {
      padding: 80px 0 120px;
    }

    > div {
      width: 50%;
      margin-left: auto;
      @include adaptive(767) {
        width: 100%;
      }
    }
  }

  &__title {
    position: absolute;
    bottom: -20px;
    right: 0;
    padding-right: 85px;
    padding-bottom: 0;
    font-size: 150px;
    line-height: 0.8;
    transform: translate(-50%, 10px);
    @include adaptive(1279) {
      font-size: 100px;
    }
    @include adaptive(1023) {
      transform: translate(0, 0);
      font-size: 80px;
    }
    @include adaptive(767) {
      padding-right: 0;
      font-size: 45px;
    }

    & + .gallery__title {
      transform: translate(0, calc(100% - 5px));
      color: #bec4c0;
      @include adaptive(767) {
        transform: translate(0, calc(100% + 2px));
      }
    }
  }

  &__desc {
    width: 100%;
    max-width: 580px;
    @include adaptive(878) {
      max-width: 100%;
    }

    &:last-of-type {
      padding-bottom: 40px;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 60px;
    @include adaptive(1279) {
      flex-direction: inherit;
      align-items: flex-start;
    }

    @include adaptive(1023) {
      flex-direction: column;
      align-items: flex-start;
    }

    a {
      display: flex;
      font-weight: bold;
      font-size: 18px;
      color: #343434;
      @include linkHoverState();
      @include adaptive(1279) {
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }

      &.white {
        color: #fff;
        &:hover:after{
          background-color: #fff;
        }
      }
    }
  }

  &__btn {
    padding-bottom: 100px;
    @include adaptive(1279) {
      padding-bottom: 50px;
    }
  }

  &__block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    padding-bottom: 30px;
    @include imageAnimation();
    @include adaptive(767) {
      gap: 16px;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 468px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// SECTION :: END
