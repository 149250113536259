.icon-arrow-right {
  width:22.751*1px;
  height:15.997*1px;
}
.icon-facebook {
  width:13.39*1px;
  height:25*1px;
}
.icon-instagram {
  width:21.887*1px;
  height:21.883*1px;
}
.icon-star {
  width:12.138*1px;
  height:11.454*1px;
}
.icon-twitter {
  width:25*1px;
  height:20.305*1px;
}
