// SECTION :: START

.hospital {
  position: relative;

  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.8);
    }

    img {
      @extend %imageResCoverAbs;
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 765px;
    padding-top: 70px;
    padding-bottom: 70px;
    @include adaptive(1439) {
      height: 600px;
    }
    @include adaptive(1023) {
      min-height: 500px;
    }

    > div {
      width: 100%;

      &:first-of-type,
      &:last-of-type {
        height: 100px;
        @include adaptive(767) {
          height: auto;
        }
      }

      &:last-of-type {
        display: flex;
        align-items: flex-end;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include adaptive(767) {
        flex-wrap: wrap;
        justify-content: center;
      }

      li {
        padding-left: 8px;
        padding-right: 8px;
        @include adaptive(767) {
          width: 50%;

          &:not(:last-of-type) {
            padding-bottom: 16px;
          }
        }
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__title {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

// SECTION :: END
