// COMMON :: START

.pages {
  padding-top: 100px;
  padding-bottom: 100px;

  ul, ol {
    margin:0 0 20px 16px;
  }

  ul li {
    list-style: disc;
  }
  ol li {
    list-style: decimal;
  }

  @include adaptive(1023) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @include adaptive(1365) {
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }

    @include adaptive(767) {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }
}

.blog {

  article {
    padding-bottom: 5rem;
  }

  h2 {
    padding-bottom: unset;
  }

}

.results {
  padding-bottom: 5rem;

  h2 {
    padding-bottom: unset;
  }
}

.copyright {
  padding-bottom: 15px;
  text-align: center;
  font-size: 15px;
  color: #000;

  a {
    font-weight: bold;
    color: #000000;
  }
}

.tos {
  display: flex;
  align-items: center;
  justify-content: center;

  a,
  span {
    font-size: 15px;
    color: #000;
  }

  a {
    @include linkHoverState();
  }

  span {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.c-scrollbar {
  z-index: 9999;
  width: .2rem;

  &_thumb {
    opacity: .75;
    width: .2rem;
    border-radius: 2rem;
  }
}

@keyframes linkAnimation {
  0% {
    width: 0;
    left: 0;
  }
  49% {
    width: 49%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

.sitemap__list {

	a {
    font-size: 15px;
	  color: #000;
	  @include linkHoverState();
	}
  
}

.flex {
  display: flex;
  justify-content: center;
}

// COMMON :: END
