// *********************************************************
// TRANSITION PROPERTIES
// *********************************************************

// general transition properties
$transition-default: 0.3s ease-in;

// animate on scroll config
$aos-duration: 0.7s;
// $aos-slide-distance: 80*$pxToRem;
$aos-slide-distance: 10vh;

// fade transition
$transition-aos-opacity:
  $aos-duration // duration in seconds
  ease // timing function
  $aos-duration * 0.2; // delay in seconds

// slide-up transition
$transition-aos-transform:
  $aos-duration * 0.85 // duration in seconds
  cubic-bezier(0.000, 1.100, 0.985, 0.985); // timing function


// *********************************************************
// PAGE ZOOM
// *********************************************************

// This percentage value is set on root html font-size rule
// to zoom anything using rem units When this is set to 100%
// 1 rem is equal to 16px
$pageZoom: 100%;

/// UTILITIES
@import "_utilities/mixin";
@import "_utilities/extend";
@import "_utilities/function";
@import "_utilities/variable";

/// VENDOR
@import "_vendor/hamburger/_hamburgers";

/// GENERATED
@import "_generated/spriteSVG";

/// SHARED
@import "_shared/main-setting-reset";
@import "_shared/grid";
@import "_shared/common";
@import "_shared/iconSVG";

// New components
@import "_theme/media";
@import "_theme/accordion";
@import "_theme/aos";
@import "_theme/sr-only";

/// MACROS
@import "_macros/logotype";
@import "_macros/title";
@import "_macros/desc";
@import "_macros/label";
@import "_macros/btn";

/// COMPONENTS
@import "_components/common/header";
@import "_components/common/footer";
//@import "_components/common/menu";
@import "_components/common/main-navigation";
@import "_components/common/heading";
@import "_components/common/our";
@import "_components/common/gallery";
@import "_components/common/meet";
@import "_components/common/reviews";
@import "_components/common/schedule";
@import "_components/common/present";

@import "_components/homepage/practice";
@import "_components/homepage/services";
@import "_components/homepage/procedures";
@import "_components/homepage/help";

@import "_components/about/staff";

@import "_components/me/education";
@import "_components/me/awards";
@import "_components/me/hospital";
@import "_components/me/presentations";
@import "_components/me/publications";

@import "_components/facelift/facelift";
@import "_components/facelift/choose";
@import "_components/facelift/faq";

@import "_components/gallery/portfolio";

@import "_components/contacts/contact";
@import "_components/contacts/address";

@import "_components/subpage";
@import "_components/404";

@include adaptive(767) {
  #wpadminbar {
    display: none;
  }  
}

