// TITLE :: START

.c-title {
  padding-bottom: 50px;
  text-transform: uppercase;
  font-family: "Tenor Sans", sans-serif;
  font-weight: normal;
  line-height: 1.2;
  font-size: 60px;
  color: #4a4a4a;
  @include titleAnimation();
  @include adaptive(1439) {
    font-size: 55px;
  }
  @include adaptive(1279) {
    font-size: 50px;
  }
  @include adaptive(1023) {
    font-size: 45px;
  }
  @include adaptive(767) {
    font-size: 35px;
  }

  &--center {
    text-align: center;
  }

  &--white {
    color: #ffffff;
  }

  &--medium {
    font-size: 40px;
    @include adaptive(1023) {
      font-size: 35px;
    }
    @include adaptive(767) {
      font-size: 30px;
    }
  }
  &--small {
    padding-bottom: 25px;
    font-size: 20px;
  }
  &--lengthy-headline {      
    @include media-desktop-only {
      max-width: calc(100% - 20vw);
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// TITLE :: END
