// HEADER :: START

.header {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  backdrop-filter: blur(15px);
  background-color: rgba(0,0,0,0.2);
  border-bottom: 1px solid rgba(0,0,0,0.2);
  @include transition(border-color, background-color);

  &.is-dark {
    border-color: rgba(#5c5c5c, 0.5);
    background-color: rgba(#5c5c5c, 0.5);
    @include transition(border-color, background-color);
  }

  &.is-scroll {
    background-color: rgba(0,0,0,0.5);
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    @include adaptive(767) {
      height: 75px;
    }  
    
    .office {
      display: grid;
      grid-template-columns: 75px 1fr;
      align-items: center;
      width: unset;

      @include adaptive(767) {
        grid-template-columns: 50px 1fr;
      }
    }

    > div {
      // &:nth-of-type(1),
      // &:nth-of-type(3) {
      //   width: 300px;
      //   @include adaptive(1279) {
      //     width: 275px;
      //   }
      //   @include adaptive(767) {
      //     width: auto;
      //   }
      // }

      &:nth-of-type(2) {
        @include adaptive(1023) {
          display: none;
        }
      }

      &:nth-of-type(3) {
        display: flex;
        justify-content: flex-end;
      }
    }

  }

  &__address {
    overflow: hidden;
    display: flex;
    font-size: 16px;
    color: #fff;

    span {
      padding-left: 8px;
      padding-right: 8px;
    }
    @include adaptive(767) {
      font-size: 14px;
    }
    @include adaptive(374) {
      font-size: 12px;
    }

    a {
      margin-left: 3px;
      font-size: 16px;
      color: #fff;
      @include linkHoverState(#fff, #fff);
      @include adaptive(767) {
        font-size: 14px;
      }
      @include adaptive(374) {
        font-size: 12px;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10rem;

    a {
      display: flex;
      align-items: center;
      margin-left: 25px;
      margin-right: 25px;
      font-size: 16px;
      color: #fff;
      @include linkHoverState(#fff, #fff);
      @include adaptive(1279) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  &__btn {
    .hamburger {
      margin-right: 5px;
    }
  }
}

// HEADER :: END
