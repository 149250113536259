// LOGOTYPE :: START

.logo {
  display: flex;
  align-items: center;

  &.logo--footer {
    justify-content: center;
  }

  &__btn {
    display: flex;
    align-items: center;

    @include adaptive(767) {
      width: calc(100% - 30px);
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}

// LOGOTYPE :: END
