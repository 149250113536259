////
/// @group  Extend
////


// GRID
%grid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
// GRID :: end


// IMAGE
%imageResCoverAbs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
// IMAGE :: end
