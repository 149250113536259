// SECTION :: START

.help {
  position: relative;
  margin-top: 135px;
  padding-top: 135px;
  @include adaptive(1279) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#fff, 0.75);
      @include adaptive(1023) {
        opacity: 1;
      }
    }

    img {
      @extend %imageResCoverAbs;
    }
  }

  &__box {
    z-index: 4;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 212px;
    padding: 50px 100px;
    background-color: #484848;
    @include adaptive(1023) {
      padding: 64px 100px 64px 32px;
    }
    @include adaptive(767) {
      width: 100%;
      padding: 32px 16px;
      padding-right: 75px;
    }

    p {
      padding-bottom: 0;
    }

    a {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      background-color: #6ea5a9;
      @include adaptive(767) {
        width: 75px;
        height: 75px;
      }

      svg {
        fill: #fff;
      }
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
    padding-top: 55px;
    padding-bottom: 200px;
    @include adaptive(1439) {
      padding-top: 50px;
      padding-bottom: 150px;
    }
    @include adaptive(1279) {
      padding-bottom: 100px;
    }
    @include adaptive(767) {
      padding-bottom: 0;
    }

    > div {
      width: 45%;
      @include adaptive(1439) {
        width: 40%;
      }
      @include adaptive(767) {
        width: 100%;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin-left: auto;
        @include adaptive(767) {
          margin-right: auto;
          margin-bottom: 32px;
        }
      }
    }
  }

  &__desc {
    width: 100%;
    max-width: 580px;
  }
}

// SECTION :: END
