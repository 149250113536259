// SECTION :: START

.address {
  @include adaptive(1023) {
    padding-bottom: 75px;
  }

  &__wrapper {
    &-1 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include imageAnimation();
      @include adaptive(767) {
        grid-template-columns: 1fr;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    &-2 {
      display: flex;
      justify-content: center;
    }
  }

  &__box {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    max-width: 755px;
    min-height: 212px;
    padding: 60px 90px;
    transform: translateY(-50%);
    // margin-left: auto;
    background-color: #5c5c5c;
    @include adaptive(1439) {
      min-height: unset;
      padding: 30px 45px;
    }
    @include adaptive(1023) {
      max-width: 100%;
      transform: translateY(0);
    }
    @include adaptive(767) {
      flex-wrap: wrap;
      padding: 32px 16px;
    }

    > div {
      @include adaptive(767) {
        width: 100%;
      }

      & + div {
        padding-left: 15px;
        @include adaptive(767) {
          padding-left: 0;
          padding-top: 16px;
        }
      }
    }

    &-subtitle {
      display: block;
      padding-bottom: 4px;
      font-size: 16px;
      line-height: 28px;
      color: #fff;
    }

    i {
      display: block;
      font-size: 14px;
      color: #b7b7b7;
    }
  }
}

// SECTION :: END
