// SECTION :: START

.faq {
  padding-top: 200px;
  padding-bottom: 200px;
  @include adaptive(1439) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &__collapse {
    display: flex;
    flex-direction: column;
    // border-bottom: 1px solid #C4C4C4;

    &-head {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 85px;
      padding-left: 65px;
      padding-right: 65px;
      @include adaptive(767) {
        padding-left: 32px;
        justify-content: flex-start;
      }

      &.is-active {
        span {
          &:after {
            transform: translateX(-50%) rotate(90deg);
            @include transition(transform);
          }
        }
      }

      p {
        font-weight: bold;
        font-size: 22px;
        text-align: center;
        color: #343434;
        @include adaptive(1023) {
          font-size: 20px;
        }
        @include adaptive(767) {
          font-size: 16px;
          text-align: left;
        }
      }

      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        @include adaptive(1023) {
          width: 20px;
          height: 20px;
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          background-color: #343434;
        }

        &:before {
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 100%;
          height: 2px;
        }

        &:after {
          top: 0;
          transform: translateX(-50%);
          left: 50%;
          width: 2px;
          height: 100%;
          @include transition(transform);
        }
      }
    }

    &-body {
      display: none;
      padding: 32px;
      // border-top: 1px solid #C4C4C4;

      p {
        padding-bottom: 0;
      }

      ul {
        margin-top: 20px;

        li {
          list-style: disc;
          color: #343434;
          font-size: 16px;
          margin-left: 2em;
        }
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;
      //border-top: 1px solid #C4C4C4;
      //border-left: 1px solid #C4C4C4;
      //border-right: 1px solid #C4C4C4;
    }
  }
}

// SECTION :: END
