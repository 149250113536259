// SECTION :: START

.heading {
  position: relative;

  &.is-inview {
    .heading {
      &__bg {
        img {
          transform: scale(1);
          @include transition(opacity, transform 2s);
          transition-delay: 0.1s;
        }
      }
    }
  }

  &.heading--homepage {
    .heading {
      &__wrapper {
        height: 100vh;

        h1 {
          color: #FFF;

          @media screen and (max-width: 960px) {
            font-size: 2.5rem;
          }

          @media screen and (max-width: 768px) {
            font-size: 1.75rem;
            text-align: center;
          }
        }
      }

      &__title {
        svg {
          display: block;
          max-width: 100%;
          height: auto;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  &__bg {
    z-index: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.45);
    }

    img {
      z-index: 0;
      @extend %imageResCoverAbs;
      transform-origin: center;
      animation: fadeInAnimation ease 3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
        @keyframes fadeInAnimation {
            0% {
              transform: scale(1.3);
                opacity: 0;
            }
            100% {
              transform: scale(1);
                opacity: 1;
            }
        }
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 750px;
    min-height: 750px;
    padding-top: 150px;
    padding-bottom: 225px;
    @include adaptive(767) {
      height: 550px;
      min-height: 550px;
    }
  }

  &__subtitle {
    padding-bottom: 35px;
    font-family: "Tenor Sans", sans-serif;
    font-weight: normal;
    font-size: 22px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    color: #FFF;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 4px;

    @media screen and (max-width: 768px) {
      display: none;
    }

    img {
      display: block;
      max-width: 310px;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__title {
    padding-bottom: 35px;
    @include titleAnimation();
  }

  &__desc {
    font-size: 16px;
    text-align: center;
    color: #fff;
  }

  &__logo {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 115px;
    margin-left: auto;
    margin-right: auto;

      @include adaptive(767) {
      bottom: 30px;
    }

  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    color: #fff;

    &-wrapper,
    nav {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ol {
      list-style-type: none;
      padding-inline-start: 0;
    }

    li {
      display: inline;

      @include adaptive(767) {
        &:first-of-type {
          display: none;
        }
      }
    }

    // a 
    li a {
      display: inline-flex;
      margin-left: 3px;
      margin-right: 3px;
      font-size: 16px;
      text-align: center;
      color: #fff;
      font-weight: 400;
    }

    // p
    li {
      // display: flex;
      margin-left: 3px;
      margin-right: 3px;
      font-size: 16px;
      text-align: center;
      color: #fff;
      font-weight: 600;
    }

    li:after  {
      content: "|";
      display: inline;
      padding: 0 8px;
      height: 16px;

      @include adaptive(767) {
        padding: 0;
      }
  
    }

    li:last-child:after  {
      content: "";
    }

    span {
      margin-left: 6px;
      margin-right: 6px;
      font-weight: normal;
  
      &.breadcrumb_last {
        font-weight: bold;
        font-size: 16px;
      }
    }

    a {
      color: #ffffff;
      @include linkHoverState(#6EA5A9, #6EA5A9);

      &:nth-of-type(2n) {
        pointer-events: none;
      }
    }

    p {
      color: #fff;
      font-weight: bold;
    }
  }
}

// SECTION :: END
