// 404 :: START

.not-found {
	.wrap {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 100px;
		@include adaptive(767) {
			grid-template-columns: 1fr;
			gap: 0;
		  }
	}

	nav {
		display: flex;
		flex-direction: column;
	
		.c-title--small {
			padding-bottom: 0;
			margin-bottom: 15px;
		}
	
		a {
			color: #000;
			align-self: flex-start;
			@include linkHoverState();
		}
	}

	p {
		margin-bottom: 25px;
	}

	.contact__form-field--btn {
		padding-bottom: 25px;
		padding-top: 0;
	}

}

@keyframes linkAnimation {
	0% {
		width: 0;
		left: 0;
	}

	49% {
		width: 49%;
	}

	50% {
		width: 50%;
	}

	100% {
		width: 0;
		left: 100%;
	}
}



// 404 :: END