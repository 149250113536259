/* accordion module: theme */

.accordion {
  text-align: left;
  list-style: none;
  padding: 0;

  &:not(:first-child) {
    margin-top: 100px;
  }

  .accordion__item {
    border: 1px solid #C4C4C4;
    padding: 10px;

    &:not(:first-child) {
      border-top-width: 0;
    }
  }

  .accordion__header {
    margin: 0;

    button {
      display: flex;
      transition: color $transition-default;
      text-align: left;
      width: 100%;
      height: 100%;
      background: transparent;
      padding: 23px 40px;
      border: none;

      @include media-mobile-only {
        padding-left: 30px;
        padding-right: 30px;
      }

      .c-title--small {
        padding-bottom: unset;
      }

      .accordion__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin: 0 0 0 auto;
        position: relative;
        padding-left: 40px;

        @include media-mobile-only {
          margin-top: 0;
        }
  
        &:before,
        &:after {
          display: block;
          content: '';
          position: absolute;
          background: #000;
          width: 4px;
          height: 24px;
          transition: transform $transition-default,
                      opacity $transition-default,
                      background-color $transition-default;
        }
  
        &:before {
          transform: rotate(90deg);
        }
      }

      &:hover {
        color: #6ea5a9;
        cursor: pointer;

        .accordion__icon {
          &:before,
          &:after {
            background: #6ea5a9;
          }
        }
      }

      &:active {
        color: #000;
        transition-duration: 0s;

        .accordion__icon {
          &:before,
          &:after {
            background: #000;
            transition-duration: 0s;
          }
        }
      }
    }

    button:last-of-type{
      // border-bottom: 1px solid #000;
    }
  }

  .accordion__panel {
    height: 0;
    overflow: hidden;
    transition: height $transition-default;

    .accordion__content {
      padding: 32px 80px 64px 80px;
      opacity: 0;
      position: relative;
      transition: opacity $transition-default;
      color: #000;

      @include media-mobile-only {
        padding: 24px 48px 48px 48px;
      }
    }

    // Offsetting default list styles by 1 level since parent structure is itself a list
    ul {
      list-style-type: disc;

      ul {
        list-style-type: circle;

        ul {
          list-style-type: square;
        }
      }
    }
  }

  // Transitioning state
  .accordion__item--expanding {
    .accordion__content {
      opacity: 1;
    }
  }

  // Expanded state
  .accordion__item--expanded {
    .accordion__panel {
      overflow: visible;
      height: auto;

      .accordion__content {
        opacity: 1;
      }
    }
  }

  .accordion__item--expanding,
  .accordion__item--expanded {
    .accordion__header {
      button {
        border-bottom: 1px solid #C4C4C4;
        .accordion__icon {
          &:after {
            transform: rotate(90deg);
            opacity: 0;
          }
        }
      }
    }
  }
}

ol.accordion {
  counter-reset: counter;

  & > li {
    counter-increment: counter;
  
    .accordion__header button span:first-child::before {
      content: counter(counter, decimal-leading-zero);
      padding-right: 12px;
      opacity: 0.33;
    }
  }
}
