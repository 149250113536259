// SECTION :: START

.choose {
  position: relative;

  &.choose--best {
    .choose {
      &__box {
        left: auto;
        right: 0;
      }

      &__wrapper {
        &-2 {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin: 0 auto;
          @include adaptive(767) {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }

  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.77);
    }

    img {
      @extend %imageResCoverAbs;
    }
  }

  &__box {
    z-index: 5;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 212px;
    padding: 32px 16px;
    background-color: #484848;
    @include adaptive(767) {
      width: 75%;
    }

    .c-title {
      padding-bottom: 0;
    }
  }

  &__container {
    z-index: 2;
    position: relative;
    padding: 270px 0 220px;
    @include adaptive(1439) {
      padding-top: 225px;
      padding-bottom: 200px;
    }
    @include adaptive(1279) {
      padding-top: 175px;
      padding-bottom: 125px;
    }
    @include adaptive(767) {
      padding-bottom: 75px;
    }
    &.right-facelift {
        padding: 100px 0 100px;
    }
  }

  &__wrapper {
    gap: 85px;
    width: 100%;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    @include adaptive(1023) {
      gap: 32px;
    }

    &-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include adaptive(767) {
        grid-template-columns: 1fr;
      }
    }

    h3 {
      font-size: 20px;
      padding-bottom: unset;
    }
    
    li {
      list-style: square;
      line-height: 2;
      margin-left: 2em;
      color: #fff;
    }

    .right-facelift & {
      max-width: unset;
    }
  }
}

.container {
  padding: 270px 0 220px;
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;

  @include adaptive(767) {
    grid-template-columns: 1fr;
  }

}

// SECTION :: END
