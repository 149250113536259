// SECTION :: START

.publications {
  position: relative;
  padding-top: 145px;
  padding-bottom: 200px;
  @include adaptive(1439) {
    padding-bottom: 145px;
  }
  @include adaptive(1023) {
    padding-top: 75px;
    padding-bottom: 100px;
  }
  @include adaptive(767) {
    padding-bottom: 0;
  }

  > div {
    @include adaptive(767) {
      display: flex;
      flex-direction: column;

      .c-grid-fluid {
        max-width: 100%;
        order: 2;
      }
    }
  }

  &__bg {
    z-index: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: calc(100% - 330px);
    @include adaptive(1023) {
      height: calc(100% - 250px);
    }
    @include adaptive(767) {
      position: static;
      transform: unset;
      height: auto;
    }

    .c-grid {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      @include adaptive(767) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.5rem;
        margin-bottom: 2.5rem;
      }

      > div {
        height: 100%;
        padding-left: 8px;
        padding-right: 8px;
        @include adaptive(767) {
          padding-left: 0;
          padding-right: 0;
          
          &:not(:last-of-type) {
            padding-bottom: 16px;
          }
        }

        &:nth-of-type(2),
        &:nth-of-type(4) {
          display: flex;
          align-items: flex-end;
        }
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
    min-height: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include adaptive(767) {
      min-height: unset;
    }


  }

  &__title {
    padding-bottom: 100px;
    color: #6ea5a9;
    font-size: 228px;
    @include adaptive(1799) {
      padding-top: 100px;
      font-size: 175px;
    }
    @include adaptive(1439) {
      font-size: 125px;
    }
    @include adaptive(1023) {
      font-size: 80px;
    }
    @include adaptive(767) {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 40px;
    }
  }

  &__btn {
    &-1 {
      @include adaptive(767) {
        display: none;
      }
    }
    &-2 {
      display: none;
      margin-top: 50px;
      @include adaptive(767) {
        display: flex;
      }
    }
  }


}

.publications__list {

  padding-top: 0;

      .hide {
        display: none;
      }

}

// SECTION :: END
