// SECTION :: START

.awards {
  padding-top: 100px;
  padding-bottom: 100px;
  @include adaptive(1023) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  ul {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 848px;
    margin-left: auto;
    margin-right: auto;

    &.gradient {
      &:after {
       content: '';
       position: absolute;
       left: 0;
       bottom: 0;
       width: 100%;
       height: 100%;
       background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
      }
    }

    &.start {
      p {
        text-align: left;
      }
    }

    &.hide {
      display: none;
    }

    li {
      list-style-type: none;
      &:not(:last-of-type) {
        padding-bottom: 55px;
        @include adaptive(1023) {
          padding-bottom: 32px;
        }
      }

      h3 {
        font-size: 1.5rem;
        text-align: center;
      }
    }

    p {
      // font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      color: #343434;
      text-align: center;
    }
  }
}

// SECTION :: END
