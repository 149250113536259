// DESC :: START

.c-desc {
  padding-bottom: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #343434;
  @include adaptive(767) {
    font-size: 14px;
  }

  &--white {
    color: #ffffff;
  }
}
.c-desc p {
  padding-bottom: 20px;
}
.pages .c-desc {
  padding: 0;
}

// DESC :: END
