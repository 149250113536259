// LABEL :: START

.c-label {
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #6ea5a9;
  @include adaptive(1279) {
    font-size: 20px;
  }
  @include adaptive(767) {
    font-size: 16px;
  }

  &--center {
    text-align: center;
  }
}

// LABEL :: END
